import { template as template_3ebe1e2d8b9d4cf99a87dd8c18208758 } from "@ember/template-compiler";
const FKLabel = template_3ebe1e2d8b9d4cf99a87dd8c18208758(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
