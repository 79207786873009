import { template as template_2ab57f3189504af7878e1ff06486fe76 } from "@ember/template-compiler";
const WelcomeHeader = template_2ab57f3189504af7878e1ff06486fe76(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
