import { template as template_df68f154d0d24b459b3a6e7e9490a5a5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_df68f154d0d24b459b3a6e7e9490a5a5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
